import React, {memo, useState, useEffect, useCallback} from 'react'
import {Location} from '@reach/router'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Download = memo(props => {

    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (props) {
            const tinyurlId = props.location.pathname.replace("/download/", "");
            const url = `https://bit.ly/${tinyurlId}`;
            setUrl(url)
        }
    }, [props]);

    const clickLink = useCallback((link) => {
        link && link.click()
    }, []);


    return (
        <Layout>
            <SEO
                googleTitle="Medoboard - optimiert Ihre Ordination"
                googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
                fbUrl="https://medoboard.com/download"
                fbTitle="Medoboard - PDF Befund"
                fbDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten."
                keywords={[
                    `medoboard`,
                    `Ordinationssoftware`,
                    `intelligent`,
                    `einfach`,
                    `Patientenakte`,
                    `Arztsoftware`,
                ]}
            />
            <div style={{height: "70vh"}}>
                {url}
                Der Download Ihres PDF Befunds startet automatisch! Ansonsten können Sie den Download <a href={url}
                                                                                                         download
                                                                                                         ref={link => clickLink(link)}>hier</a> manuell
                starten.
            </div>
        </Layout>
    )
});

const DownloadWrapper = memo(props => {

    return (
        <Location>
            {locationProps => <Download {...locationProps} {...props} />}
        </Location>
    )
});

export default DownloadWrapper
